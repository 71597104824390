@import 'mixins';
@import 'fonts';

// This fixes author device emulator background overwrite problem.
#cq-emulator-content.firefox {
  background-color: #fff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  margin: 15px 0;
}

a {
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

.selection-bold {
  font-weight: bold;
}

input[type="search"] {
  box-sizing: content-box;
}

.only-mobile, .only-tablet, .only-desktop {
  display: none;
}

.component {

  &-content {
    @include clearfix;
  }

}

.clearfix {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }

  &:after {
    clear: both;
  }
}

.pull {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}

/* forms */

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* spacing */

.spacer {
  clear: both;
  height: 20px;
}

.spacer > div {
  margin: 0;
  padding: 0;
  border: none !important;
  border-color: transparent;
}

.no-space {
  margin: 0;
  padding: 0;
}

.space-inside-top {
  padding-top: 30px;
}

.space-inside-left {
  padding-left: 30px;
}

.space-inside-right {
  padding-right: 30px;
}

.space-inside-bottom {
  padding-bottom: 30px;
}

/* alignments */

.middle {
  margin: 0 auto;
  text-align: center;
}

.right {
  margin: 0 0 0 auto;
  text-align: right;
}

.vertically-middle .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vertically-middle .content > * {
  display: inline-block;
}

/* borders */

.border-top {
  border-top: 1px solid #e4e4e4;
}

.border-left {
  border-left: 1px solid #e4e4e4;
}

.border-right {
  border-right: 1px solid #e4e4e4;
}

.border-bottom {
  border-bottom: 1px solid #e4e4e4;
}

/* misc */

.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

.paragraphSystem, .row {
  margin: 0;
}

.disclaimer {
  color: #ccc;
}

.disclaimer a {
  text-decoration: underline;
  color: #ccc;
}

/* main */

html, body {
  width: 100%;
  font-size: 1rem;
  background: $lightest-grey;
}

body {
  position: relative;
  overflow: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'GSKPrecision', sans-serif;
  font-weight: 400;
}

.container {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

body > .container > .row {
  margin: 0;
  padding: 0;
}

/* layout: header */

div#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  width: 100%;
  max-width: 100%;
  padding: 0;
  border-bottom: 1px solid #dee2e6;
  overflow: visible;
  background: #fff;
  z-index: 9999;
}

div#header div[class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.reference-header > .inner {
  margin: 0 auto;
  max-width: 1140px;
}

.reference-header > .inner > .component-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.reference-header .header-sidebar > div {
  margin-left: auto;
}

.reference-header .image {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header .richText {
  padding-top: 0;
  padding-bottom: 0;
}

.reference-header .header-logo{
  width: auto;
  line-height: $header-height;
}

.reference-header .header-logo-secondary {
  text-align: center;
}

.reference-header .header-logo > div {
  text-align: left;
}

.reference-header .header-logo-secondary > div {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}

.reference-header .header-logo a,
.reference-header .header-logo-secondary > div {
  display: inline-block;
  height: $header-height;
  line-height: $header-height;
}

.reference-header .header-logo img,
.reference-header .header-logo-secondary img {
  display: inline-block;
  vertical-align: middle;
}

.reference-header .header-logo img {
  height: 48px;
  max-height: 48px;
  max-width: 180px;
}

.reference-header .header-logo-secondary img {
  height: 20px;
  max-height: 20px;
}

.reference-header .box-heading {
  display: none;
}

/* layout: presenter carousel */

.carousel-content {
  min-height: 20px;
}

.carousel-content > div {
  padding: 30px;
  text-align: center;
}

.carousel-content > div .title,
.carousel-content > div .richText {
  padding: 0;
}

#content .carousel-content > div .title h1 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3rem;
  color: #FFC200;
}

#content .carousel-content > div .title h2 {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #FFC200;
}

#content .carousel-content > div .richText p {
  font-size: 1rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #FFC200;
}

/* layout: home / map */

#chartdiv {
  display: block;
  max-width: 1500px;
  margin: 0 auto;
  height: 320px;
}

/* layout: content / article compact */

#content .article-compact {
  clear: both;
  float: none;
  width: 100%;
}

#content .article-compact > div {
  float: none;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 1140px;
}

/* layout: content / buttons */

#content .button-orange,
#content .button-blue {
  position: relative;
  display: inline-block;
  margin: 8px 8px 8px 0;
  padding: 10px 24px;
  font-size: 1rem !important;
  line-height: 1.4rem !important;
  font-weight: 700;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  transition: all 0.2s linear 0s;
}

#content .button-orange:before,
#content .button-blue:before {
  @include font-awesome($fa-var-caret-right);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  width: 30px;
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
}

#content .button-orange:hover,
#content .button-blue:hover {
  padding-left: 50px;
}

#content .button-orange:hover:before,
#content .button-blue:hover:before {
  transform: scale(1, 1);
  text-indent: 0;
}

#content .button-orange {
  color: #fff !important;
  background: $bright;
}

#content .button-orange:hover {
  color: #fff !important;
  background: $dark;
}

/* layout: content / richText */

#content .richText p {
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0;
  padding: 10px 0;
  word-wrap: break-word;
}

#content .richText a {
  font-size: 1rem;
  line-height: 1.6rem;
  color: $bright;
}

#content .richText ul {
  margin: 15px 0 15px 30px;
  padding: 0;
}

#content .richText ul li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 15px;
  font-size: 1rem;
  line-height: 1.6rem;
  word-wrap: break-word;
}

#content .richText ol {
  margin: 15px 0 15px 30px;
  padding: 0;
}

#content .richText ol li {
  position: relative;
  margin: 12px 0;
  padding: 0 0 0 15px;
  font-size: 1rem;
  line-height: 1.6rem;
  word-wrap: break-word;
}

/* layout: content */

div#content {
  margin: $header-height 0 0 0;
  padding: 0;
  background: #fff;
}

div#content .layout-inner > div.col-xs-12, div#content .layout-inner > div.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

#content h1,
#content h2,
#content h3,
#content h4,
#content h5,
#content h6 {
  font-family: 'GSKPrecision', sans-serif;
}

#content h1 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #000;
}

#content h2 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: $bright;
}

#content h4 {
  margin: 0;
  padding: 15px 0;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: #000;
}

#content h6 {
  margin: 0;
  padding: 15px 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: $bright;
}

/* layout: content / table */

#content .table {
  margin: 0;
  padding: 0;
  overflow-x: scroll;
}

#content .table table {
  width: 100%;
  min-width: 600px;
  margin: 15px 0;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
}

#content .table table tbody {
  margin: 0;
  padding: 0;
}

#content .table table tr {
  background: #fff;
}

#content .table table tr:nth-child(odd) {
  background: #f6f6f6;
}

#content .table table tr:nth-child(odd) td {
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}

#content .table table tr:first-child {
  background: #fff;
}

#content .table table th {
  margin: 0;
  padding: 10px 15px;
  vertical-align: top;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8rem;
}

#content .table table td {
  margin: 0;
  padding: 10px 15px;
  vertical-align: top;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8rem;
}

#content .table table tr:first-child td {
  font-size: 1rem;
  font-weight: 700;
  color: #333;
  border-top: none;
  border-bottom: 1px solid #464646;
}

/* layout: content / infobox */

.infobox, .warningbox {
  position: relative;
  margin: 20px 0;
}

.infobox > div,
.warningbox > div {
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
  background: $light;
}

.infobox > div {
  background: $lighter-grey;
}

.warningbox > div {
  background: $light;
}

.infobox h2, #content .infobox h2,
.warningbox h2, #content .warningbox h2 {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

/* layout: content / gray box */

.gray-box {
  position: relative;
  margin: 20px 0;
}

.gray-box > div {
  padding: 15px 30px;
  background: $lighter-grey;
  border-radius: 15px;
}

#content .gray-box.richText p, .gray-box p, #content .gray-box.richText a {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

/* layout: content / iconbar */

.icon-bar {
  position: relative;
  margin: 20px 0;
}

.icon-bar > div {
  padding: 15px 30px 15px 80px;
  background: $lighter-grey;
  border-radius: 15px;
}

#content .icon-bar.richText p, .icon-bar p, #content .icon-bar.richText a {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.icon-bar:before {
  position: absolute;
  top: 22px;
  left: 40px;
  font-size: 2rem;
}

.icon-bar.icon-check:before {
  @include font-awesome($fa-var-circle-check);
}

.icon-bar.icon-doctor:before {
  @include font-awesome($fa-var-user-doctor);
}

.icon-bar.icon-syringe:before {
  @include font-awesome($fa-var-syringe);
}

/* layout: content / articleTeaserV2 */

.articleTeaserV2 {
  margin: 20px 0 0 0;
  padding-bottom: 20px;
}

.articleTeaserV2 > div {

}

.articleTeaserV2 .article-teaser-block {
  display: flex;
  flex-flow: wrap;
}

.articleTeaserV2.article-teaser-image-left .article-teaser-block {
  flex-direction: row;
}

.articleTeaserV2.article-teaser-image-right .article-teaser-block {
  flex-direction: row-reverse;
}

.articleTeaserV2 .aspect-ratio-box {
  width: 100%;
  height: 240px;
  box-sizing: border-box;
  overflow: hidden;
}

.articleTeaserV2 .aspect-ratio-box img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  transition: transform 0.4s;
}

.articleTeaserV2:hover .aspect-ratio-box img {
  transform: scale(1.05);
}

.articleTeaserV2 .article-teaser-content {
  width: 100%;
  max-height: 370px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 0;
}

.articleTeaserV2 .article-teaser-content .label {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.articleTeaserV2 .article-teaser-content .label p {
  margin: 0;
  padding: 0;
}

.articleTeaserV2:hover .article-teaser-content .label p {
  text-decoration: underline;
}

.articleTeaserV2 .article-button-container p.button-text a {
  position: relative;
  display: inline-block;
  padding-left: 26px;
  color: $bright;
}

.articleTeaserV2 .article-button-container p.button-text a:before {
  position: absolute;
  top: 2px;
  left: 0;
  @include font-awesome($fa-var-arrow-right);
  transition: all 0.2s linear 0s;
}

.articleTeaserV2:hover .article-button-container p.button-text a:before {
  left: 4px;
}











/* bg styles */

.background-light-gray {
  background-color: #ececec;
}

.background-light-gray-inside > div {
  background-color: #ececec;
}

/* formatting */

.superscript {
  font-size: 70%;
  vertical-align: super;
}

/* divider */

.divider {
  display: block;
  width: 100%;
  padding: 15px 15px 0 15px;
  clear: both;
}

.divider > div {
  display: block;
  width: 100%;
  height: 15px;
  border-top: 1px solid #e5e5e5;
}

.divider.clearer > div {
  height: 0.01rem;
  clear: both;
  border: none;
}

/* approval code */

.approval-code {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ccc;
}

.button-white, #content .richText .button-white {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 16px 35px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  background: #fff;
  color: #3c2e6b;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-transform: uppercase;
  transition: all 0.2s linear 0s;
}

.button-white:before, .richText .button-white:before {
  content: "";
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  width: 30px;
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
}

.button-white:hover, .richText .button-white:hover {
  text-indent: 30px;
}

.button-white:hover:before, .richText .button-white:hover:before {
  transform: scale(1, 1);
  text-indent: 0;
}

.button-blue, #content .richText .button-blue, #content .about .richText .button-blue {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  padding: 16px 35px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  background: #3c2e6b;
  background: linear-gradient(190deg, rgba(58,47,98,1) 0%, rgba(51,118,189,1) 100%);
  color: #fff;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-transform: uppercase;
  transition: all 0.2s linear 0s;
}

.button-blue:before, .richText .button-blue:before {
  content: "";
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  width: 30px;
  border-radius: 0 50% 50% 0;
  transform: scale(0, 1);
  transform-origin: left center;
  transition: all 0.2s linear 0s;
}

.button-blue:hover, .richText .button-blue:hover {
  text-indent: 30px;
}

.button-blue:hover:before, .richText .button-blue:hover:before {
  transform: scale(1, 1);
  text-indent: 0;
}

/* icons */

.lock-icon:before {
  @include font-awesome($fa-var-lock);
  padding-right: 10px;
}

.calendar-info:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
}

.time-info:before {
  @include font-awesome($fa-var-clock);
  padding-right: 10px;
}

.icon-pdf:before {
  @include font-awesome($fa-var-file-pdf);
  padding-right: 10px;
}

.icon-download:before {
  @include font-awesome($fa-var-download);
  padding-right: 10px;
}

.icon-back:before {
  @include font-awesome($fa-var-chevron-left);
  padding-right: 10px;
}

/* layout: content / image */

.image {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.image figcaption {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.image a {
  display: block;
  position: relative;
}

.image.link a:before, .image.download a:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  content: " ";
  height: 40px;
  width: 40px;
  background: $bright url('../../assets/img/download-icon.svg') no-repeat center center;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-box-shadow: 0 0 8px 2px #ddd;
  -moz-box-shadow: 0 0 8px 2px #ddd;
  box-shadow: 0 0 8px 2px #ddd;
}

.image.link a:before {
  background: $bright url('../../assets/img/next-arrow-icon.svg') no-repeat center center;
}

.image.link a:hover:before {
  left: 52%;
  transform: translate(-48%, -50%);
}

.image.download a:before {
  background: $bright url('../../assets/img/download-icon.svg') no-repeat center center;
}

.image.download a:hover:before {
  top: 52%;
  transform: translate(-50%, -48%);
}

.image img {
  max-width: 100%;
}

/* layout: content / legal note */

#content .legal-note {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #888;
}

/* layout: content / about */

.about {
  margin: 0;
  background: rgb(231,252,255);
  background: linear-gradient(180deg, rgba(231,252,255,1) 0%, rgba(255,255,255,1) 15%);
}

.about > div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.about .title {
  display: block;
  text-align: center;
}

#content .about .title h2 {
  display: inline-block;
  margin: 0 auto;
  color: #3c2e6b;
  font-weight: 700;
}

.about .richText {
  display: block;
  text-align: center;
}

.about .richText > div {
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 80%;
}

#content .about .richText p {
  margin: 0;
  padding: 10px 0;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #3c2e6b;
}

#content .about .richText a {
  font-size: 1rem;
  line-height: 1.6rem;
  color: $bright;
}

#content .about .title h2 {
  padding: 20px 15px;
  border-bottom: 2px solid $bright;
}

.hoverimage > div {
  position: relative;
}

.hoverimage > div img {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.hoverimage .hover-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: rgba(59, 46, 107, 0.8);
  color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.6rem;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

/* layout: content / sources */

.sources {

}

.sources > div {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.sources.richText, .disclaimer.richText {
  margin: 20px 0;
  color: #9B9A96;
}

.sources.richText p,
#content .sources.richText p,
.disclaimer .richText p,
#content .disclaimer.richText p {
  margin: 5px 0;
  padding: 0;
  font-size: 0.75rem;
  line-height: 1.1rem;
}

.sources.richText a,
#content .sources.richText a,
.disclaimer .richText a,
#content .disclaimer.richText p {
  font-size: 0.75rem;
  line-height: 1.1rem;
}

.sources .richText a {
  color: #9B9A96;
}

/* layout: footer */

div#footer {
  margin: 0;
  padding: 80px 0 0 0;
  color: $dark-grey;
  background: url(../../assets/img/precision.svg) no-repeat top center;
  background-size: 80px;
}

.reference-footer > .inner {
  margin: 0 auto;
  max-width: 1140px;
}

.reference-footer .component-content {
  text-align: center;
}

.reference-footer .image {
  margin: 0;
  padding: 10px;
}

.reference-footer .image img {
  width: 50px;
}

.reference-footer .richText ul {
  display: block;
  margin: 0;
  padding: 10px 0;
  list-style-type: none;
}

.reference-footer .richText ul li {
  display: block;
  margin: 0;
  padding: 10px 0;
}

.reference-footer .richText ul li a {
  font-size: 1rem;
  font-weight: 400;
  color: $dark-grey;
}

.reference-footer .richText ul li a:hover {
  text-decoration: underline;
}

.reference-footer .richText p {
  font-size: 1rem;
  font-weight: 400;
  color: $dark-grey;
}

.reference-footer .richText .small-light-gray {
  font-size: 0.85rem;
  font-weight: 400;
  color: $dark-grey;
}

/* formatting */

.superscript {
  font-size: 70%;
  vertical-align: super;
}

/* divider */

.divider {
  @include align-center(medium);
  display: block;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.divider > div {
  display: block;
  width: 100%;
  height: 20px;
  border-top: 1px solid #e5e5e5;
}

/* icons */

.lock-icon:before {
  @include font-awesome($fa-var-lock);
  padding-right: 10px;
}

.calendar-info:before {
  @include font-awesome($fa-var-calendar);
  padding-right: 10px;
}

.time-info:before {
  @include font-awesome($fa-var-clock);
  padding-right: 10px;
}

/* approval code */

.approval-code {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ccc;
}

/* media queries */

@include respond-to(mobile) {

  /* display */

  .only-desktop {
    display: none;
  }

  .only-mobile {
    display: block !important;
  }

  /* layout: content / checklist */

  .checklist > div {
    padding: 0 15px;
  }

  .checklist .richText {
    padding: 30px 0 0 0;
  }

  .checklist .richText.checklist-right {
    border-top: 3px solid #2E79BC;
  }

}

@include respond-to(tablet) {

  /* display */

  .only-tablet {
    display: block !important;
  }

  /* header */

  div#header {
    -webkit-transition: left .25s ease-out;
    -moz-transition: left .25s ease-out;
    -o-transition: left .25s ease-out;
    transition: left .25s ease-out;
  }

  #header .searchBox {
    display: block;
    width: 100%;
  }

  #header .searchBox form {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    height: $header-height;
  }

  #header .searchBox .searchBox-title {
    display: none;
  }

  #header .searchBox form label {
    display: block;
    font-weight: 400;
  }

  #header .searchBox form input {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    margin: 0;
    padding: 4px 40px 4px 3px;
    line-height: 2rem;
    border: 1px solid #ccc;
    background: #fff;
    font-size: 1rem;
    text-indent: 12px;
  }

  #header .searchBox form button {
    position: absolute;
    top: 12px;
    right: 15px;
    height: 40px;
    width: 40px;
    background: none;
    border: 0;
  }

  #header .searchBox form button {
    color: transparent;
    font-size: 0;
    cursor: pointer;
  }

  #header .searchBox form button:before {
    position: absolute;
    content: " ";
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    top: 8px;
    left: 6px;
    font-weight: 900;
    font-size: 1.2rem;
    display: inline-block;
    color: #000;
  }

  .nav-is-open div#header {
    left: $navigation-drawer;
  }

  .reference-header > .inner > .component-content > div {
    display: block;
  }

  .reference-header .mobile-menu {
    display: flex;
    cursor: pointer;
    line-height: $header-height;
    width: 150px;
    text-align: center;
    margin: 0 15px;
  }

  .reference-header .mobile-menu > div.component-content {
    display: inline-block;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .reference-header .mobile-menu > div.component-content > * {
    display: none;
  }

  .reference-header .header-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $navigation-drawer !important;
    min-height: 100vh;
    margin-left: -$navigation-drawer;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    background: #f2f2f2;
    -ms-overflow-style: none;
    overflow: auto;
  }

  .nav-is-open .reference-header .header-sidebar {
    margin-left: 0;
  }

  .reference-header .header-sidebar:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    width: 10px;
    min-height: 100%;
    box-shadow: inset -7px 0 9px -7px rgba(0,0,0,0.2);
    content: "";
  }

  .reference-header .header-logo {
    flex-grow: 1;
  }

}

@include respond-to(desktop) {

  /* display */

  .only-desktop {
    display: block !important;
  }

  /* spacing */

  .space-inside-top-desktop {
    padding-top: 30px;
  }

  .space-inside-left-desktop {
    padding-left: 30px;
  }

  .space-inside-right-desktop {
    padding-right: 30px;
  }

  .space-inside-bottom-desktop {
    padding-bottom: 30px;
  }

  /* borders */

  .border-top-desktop {
    border-top: 1px solid #c6c6c6;
  }

  .border-left-desktop {
    border-left: 1px solid #c6c6c6;
  }

  .border-right-desktop {
    border-right: 1px solid #c6c6c6;
  }

  .border-bottom-desktop {
    border-bottom: 1px solid #c6c6c6;
  }

  /* mobile menu */

  .mobile-menu {
    display: none;
  }

  .reference-header .header-sidebar {
    flex-grow: 1;
  }

  .reference-header .header-sidebar > div {
    float: right;
  }

  .reference-header .header-logo {
    padding-right: 30px !important;
  }

  .reference-header .header-logo img {
    max-width: 220px;
  }

  .reference-header .header-logo-secondary {
    text-align: right;
  }

  /* divider */

  .divider {
    @include desktop-padding();
  }

  /* layout: content */

  .main .title {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .title:first-of-type {
    padding-top: 30px;
  }

  .main .image {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .richText {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .table {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .searchBox {
    @include align-center(medium);
    @include desktop-padding();
  }

  .main .searchResults {
    @include align-center(medium);
    @include desktop-padding();
  }

  /* layout: content / buttons */

  #content .button-orange,
  #content .button-blue {
    position: relative;
    display: inline-block;
    margin: 10px 10px 10px 0;
    padding: 13px 30px;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  #content .button-orange:hover,
  #content .button-blue:hover {
    padding-left: 50px;
  }

  /* layout: home / map */

  #chartdiv {
    height: 800px;
  }

  /* layout: content / article compact */

  #content .article-compact > div {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  /* layout: main / presenter carousel */

  #content .carousel-content > div .title h1 {
    font-size: 3rem;
    line-height: 3.6rem;
  }

  #content .carousel-content > div .title h2 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  #content .carousel-content > div .richText p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  /* layout: main / content */

  #content h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  #content h2 {
    font-size: 2.0rem;
    line-height: 2.4rem;
  }

  #content h4 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  #content h6 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  /* layout: content / flyout */

  .flyout {
    position: fixed;
    bottom: 20px;
    left: 20px;
    max-width: 400px;
    z-index: 99;
  }

  /* layout: content / articleTeaserV2 */

  .articleTeaserV2 {
    margin: 30px 0 0 0;
    padding-bottom: 30px;
  }

  .articleTeaserV2 .article-teaser-block {
    flex-flow: wrap;
  }

  .articleTeaserV2 .aspect-ratio-box,
  .articleTeaserV2 .article-teaser-content {
    width: 50%;
  }

  .articleTeaserV2 .article-teaser-content {
    padding: 20px 40px;
  }

  .articleTeaserV2 .article-teaser-content .label {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .articleTeaserV2 .aspect-ratio-box {
    height: 375px;
  }

  .articleTeaserV2 .aspect-ratio-box img {
    height: 375px;
  }




  /* layout: content / media-hub-teaser */

  .media-hub {
    padding: 20px 80px;
  }

  .media-hub > div > div {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }

  /* layout: content / about */

  #content .about .title h2 {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  /* layout: content / image teaser box */

  .image-teaser-box > div {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
  }

  .hoverimage > div {
    width: 360px;
    height: 240px;
  }

  /* layout: content / checklist download */

  .checklist-download {
    min-height: 1000px;
  }

  .checklist-download .richText {
    position: absolute;
    top: 200px;
    right: 100px;
    width: 570px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .checklist-download .richText h3 {
    font-size: 1.5rem;
  }

  /* layout: content / why vaccation */

  .why-vaccination {
    min-height: 1000px;
  }

  .why-vaccination .richText {
    position: absolute;
    top: 140px;
    left: 100px;
    width: 570px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .why-vaccination .richText h3 {
    font-size: 1.5rem;
  }

  /* layout: content / diseases */

  #content .diseases .title h2 {
    font-size: 2.4rem;
  }

  /* layout: content / checklist */

  .checklist .richText {
    padding: 0 30px;
  }

  .checklist .richText {
    margin: 20px 0;
    padding: 0 50px;
  }

  .checklist .richText.checklist-right {
    border-left: 3px solid #2E79BC;
  }

  /* layout: content / appointment */

  .appointment {
    min-height: 880px;
  }

  /* layout: content / quiz */

  .quiz {
    margin: 40px 0 0 0;
    padding: 0 300px 0 0;
    background: url('../../assets/img/quiz.png') no-repeat top right;
    background-size: 350px;
  }

  .quiz-result {
    position: relative;
    margin: 0;
    padding: 0 0 0 350px;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 250px;
  }

  .quiz-result:before {
    position: absolute;
    top: 50%;
    left: 280px;
    bottom: 20px;
    height: 95%;
    transform: translateY(-50%);
    width: 1px;
    border-top: 0;
    border-left: 3px solid #3979ac;
  }

}