@import '../core/mixins';

.searchResults-number {
  margin: 0;
  padding: 15px 0;
  font-size: 0.9rem;
  font-weight: 400;
}

.searchResults-results {
  margin: 0;
  padding: 15px 0;
  border-top: 1px solid #e3e3e3;
}

.searchResults-result {
  min-height: 200px;
  margin: 20px 0 0 0;
  padding: 0 0 15px 0;
  box-sizing: border-box;
  background: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.searchResults-result > a {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 5px 15px 5px 230px;
  min-height: 190px;
  max-height: 250px;
}

.searchResults-result h3.searchResults-title {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.7rem;
}

.searchResults-result h3.searchResults-title a {
  color: #A1BB23;
}

.searchResults-result .searchResults-date {
  margin: 0;
  padding: 10px 0 0 0;
}

.searchResults-result .searchResults-description {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
}

.searchResults-result .searchResults-url {
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  font-style: italic;
}

.searchResults-result .searchResults-image {
  position: absolute;
  width: 200px;
  top: 15px;
  left: 15px;
  bottom: 15px;
}

.searchResults-result ul.searchResults-tags {
  margin: 0;
  padding: 10px 0 0 0;
  list-style-type: none;
}

.searchResults-result ul.searchResults-tags li {
  display: inline-block;
}

.searchResults-result ul.searchResults-tags li a {
  display: inline-block;
  margin: 15px 10px 0 0;
  padding: 8px 10px;
  background: #ddd;
}

.searchResults-result ul.searchResults-tags li a {
  background: #fefefe;
}

.search-results .searchResults .searchResults-term {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.7rem;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.search-results .searchResults .searchResults-phrase {
  display: inline-block;
  font-weight: 700;
}

.searchResults-pagination {
  text-align: center;
  overflow: hidden;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: default;
}

.searchResults-pagination-list {
  padding: 0;
  margin: 0;
}

.searchResults-pagination-item {
  display: inline-block;
  line-height: 12px;
  margin: 1px;
  cursor: default;
  border-right: solid 1px #D5D1CE;
}

.searchResults-pagination-item a {
  display: inline-block;
  padding: 0 5px 0 0;
  cursor: pointer;
  line-height: 12px;
}

.searchResults-pagination-item a:hover {
  color: #f36633;
  text-decoration: none;
}

.searchResults-pagination-item:first-child {
  background: url("../../img/arrows/page-arrow-1.png") left center no-repeat;
  height: 20px;
  width: 20px;
  border-right: none;
  line-height: 20px;
}

.searchResults-pagination-item:first-child a {
  text-indent: -999px;
}

.searchResults-pagination-item:last-child {
  background: url("../../img/arrows/page-arrow-2.png") left center no-repeat;
  height: 20px;
  width: 20px;
  line-height: 20px;
  border-right: none;
}

.searchResults-pagination-item:last-child a {
  text-indent: -1299px;
}

.searchResults-pagination-item.is-active a {
  font-weight: bold;
  color: #f36633;
}

/* media queries */

@include respond-to(mobile) {



}

@include respond-to(tablet) {



}

@include respond-to(desktop) {



}