$fa-font-path: "../../fonts/fa/webfonts" !default;

@import "../../fonts/fa/scss/fontawesome.scss";
@import "../../fonts/fa/scss/solid.scss";
@import "../../fonts/fa/scss/brands.scss";

@import "../../fonts/fa/scss/duotone.scss";
@import "../../fonts/fa/scss/_duotone-icons.scss";

@mixin font-awesome($fa-var) {
  display: inline-block;
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  content: unquote("\"#{ $fa-var }\"");
}