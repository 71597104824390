.editor-panel .hiddenField {
  display: block;
}

.editor-panel .carousel {
  padding: 20px 0;
}

.editor-panel .infobox,
.editor-panel .warningbox {
  position: relative !important;
  z-index: 0 !important;
  margin: 20px 0;
  max-width: 100%;
  bottom: initial !important;
  left: initial !important;
}

@media only screen and (min-width: 990px) {

  .editor-panel .infobox,
  .editor-panel .warningbox {
    position: relative !important;
    z-index: 0 !important;
    margin: 20px 0;
    max-width: 100%;
    bottom: initial !important;
    left: initial !important;
  }

}