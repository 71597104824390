$dark: #42069C;
$bright: #6d21e0;
$medium: #9666de;
$light: rgba(233, 226, 241, 0.5);
$dark-grey: #212124;
$light-grey: #4c4d4e;
$lighter-grey: rgba(76, 77, 78, 0.1);
$lightest-grey: rgba(76, 77, 78, 0.02);
$white: #ffffff;

/* Brand A
$dark: #061f5c;
$bright: #345edb;
$medium: #89d1f3;
$light: #e0f2f0;
$dark-grey: #212124;
$light-grey: #4c4d4e;
$lighter-grey: rgba(76, 77, 78, 0.1);
$white: #ffffff;
*/

/* Brand B
$dark: #8f0000;
$bright: #e9000c;
$medium: #faaac3;
$light: #ffeeff;
$dark-grey: #212124;
$light-grey: #4c4d4e;
$lighter-grey: rgba(76, 77, 78, 0.1);
$white: #ffffff;
*/

/* Brand C
$dark: #00501e;
$bright: #0fa564;
$medium: #8de2a6;
$light: #e6ffe6;
$dark-grey: #212124;
$light-grey: #4c4d4e;
$lighter-grey: rgba(76, 77, 78, 0.1);
$white: #ffffff;
*/