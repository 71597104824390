@import '../core/mixins';

.accordion-container {
  padding: 0 0 20px 0;
  margin: 20px auto;
  list-style: none;
  border-bottom: 1px solid $medium;
}

.accordion-slide {
  position: relative;
  margin: 20px 0 0 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.accordion-title-link {
  display: flex;
  align-items: center;
}

.accordion-title-link > img {
  max-height: 100%;
  align-self: center;
}

.accordion-head {
  display: block;
  padding: 0;
  color: #000000;
  background: transparent;
  text-transform: uppercase;
  border-top: 1px solid $medium;
}

.accordion-title, #content h2.accordion-title {
  float: none;
  margin: 0;
  padding: 20px 25px 0 0;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.accordion-title a {
  color: $dark-grey;
}

.accordion-content {
  padding: 20px 0;
}

.accordion-content .richText {
  padding: 0;
}

.accordion-content .richText > div img {
  position: absolute;
  top: 0;
  left: 10px;
  width: 100px;
}

.accordion-content-wrapper {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.mod-js .accordion-content-wrapper {
  position: relative;
  height: 0;
  top: -999999px;
  left: -999999px;
  overflow: hidden;
  box-sizing: border-box;
  visibility: hidden;
}

.mod-js .accordion-head {
  position: relative;
  cursor: pointer;
}

.mod-js .accordion-head:after {
  position: absolute;
  top: 50%;
  right: 0;
  @include font-awesome($fa-var-caret-up)
}

.mod-js .accordion-slide.is-active > .accordion-head:hover,
.mod-js .accordion-slide.is-active > .accordion-head {
  color: #000000;
  cursor: pointer;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover:after,
.mod-js .accordion-slide.is-active > .accordion-head:after {
  @include font-awesome($fa-var-caret-down)
}

.mod-js .accordion-slide .accordion-head:hover {
  color: $dark-grey;
}

.mod-js .accordion-slide.is-active > .accordion-head {
  color: black;
}

.mod-js .accordion-slide.is-active > .accordion-head:hover {
  background-color: transparent;
}

.mod-js .accordion-slide.is-active > .accordion-content-wrapper {
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  visibility: visible;
}

.mod-js .accordion-slide.is-active:last-child > .accordion-content-wrapper {
  border-bottom: none;
}

@include respond-to(tablet) {

  .accordion-title, #content h2.accordion-title {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 700;
  }

}

@include respond-to(desktop) {

  .accordion-title, #content h2.accordion-title {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 700;
  }

}