@import '../core/mixins';

.navigation-mobile-menu {
  display: none;
}

div.navigation {
  margin: 0;
  padding: 0;
  width: 100%;
}

ul.navigation-root {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-family: 'GSKPrecision', sans-serif;
}

@include respond-to(tablet) {

  ul.navigation-level1 {
    width: 100%;
  }

  li.navigation-item {
    display: block;
    position: relative;
  }

  li.navigation-item a {
    position: relative;
    display: block;
    margin: 0;
    padding: 10px 10px 10px 44px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.6rem;
    text-decoration: none;
    color: $dark-grey;
    border-bottom: 1px solid #ddd;
  }

  ul.navigation-root .is-active a, ul.navigation-root .has-active-child a {
    background: #fff;
  }

  ul.navigation-level1 > li:first-child a {
    border-top: 1px solid #ddd;
  }

  ul.navigation-level1 > li.has-children > a:before {
    @include font-awesome($fa-var-angle-down);
  }

  ul.navigation-level1 > li.has-children.is-active > a:before,
  ul.navigation-level1 > li.has-children.is-open > a:before {
    @include font-awesome($fa-var-angle-down);
  }

  ul.navigation-level1 > li.has-active-child > a:before {
    @include font-awesome($fa-var-angle-down);
  }

  ul.navigation-level1 > li.has-children.has-children > a:before {
    position: absolute;
    top: 15px;
    left: 18px;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

  /* @include font-awesome($fa-var-angle-down); */

  ul.navigation-level1 li.page-login a {
    font-weight: 400;
  }

  ul.navigation-level1 li.page-search {
    display: none;
  }

  ul.navigation-level1 li a:hover {
    background: #fff;
  }

  ul.navigation-level2 {
    display: block;
  }

  ul.navigation-level1 > li.is-open ul.navigation-level2,
  ul.navigation-level1 > li.is-active ul.navigation-level2,
  ul.navigation-level1 > li.has-active-child ul.navigation-level2 {
    display: block;
  }

  ul.navigation-level2 li a {
    font-weight: 200;
    background: #fff;
  }

  ul.navigation-level2 li a:hover {
    color: #000;
  }

  ul.navigation-level2 li.is-active a {
    color: #000;
    font-weight: 700;
    background: #fff;
  }

  li.page-login a:before {
    @include font-awesome($fa-var-sign-in);
    position: absolute;
    left: 18px;
    font-weight: 900;
    font-size: 0.9rem;
    display: inline-block;
  }

}

@include respond-to(desktop) {

  /*
  Hide Reiseziele Subnav
   */

  ul.navigation-root > li.page-reiseziele > a:before {
    display: none;
  }

  ul.navigation-root > li.page-reiseziele > ul {
    display: none;
  }

  div.navigation {
    width: auto;
  }

  ul.navigation-level1 {
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul.navigation-level1 li {
    position: relative;
  }

  ul.navigation-level1 li a {
    position: relative;
    display: block;
    color: #000;
    text-decoration: none;
    height: $header-height - 10px;
    line-height: $header-height - 10px;
    padding: 0 1rem;
    white-space: nowrap;
  }

  ul.navigation-level1 li a:hover {
    color: $dark;
  }

  li.navigation-level1 > a:before {
    position: absolute;
    z-index: 999;
    @include font-awesome($fa-var-angle-down);
    text-align: center;
    bottom: 12px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @keyframes infinitemovetobottom {
    from {
      bottom: 12px;
      opacity: 1;
    }
    to {
      bottom: 8px;
      opacity: 0;
    }
  }

  li.navigation-level1 > a:hover:before {
    color: $bright;
    animation: infinitemovetobottom 0.7s linear infinite;
  }

  ul.navigation-level1 li.is-active > a, ul.navigation-level1 li.has-active-child > a {
    font-weight: 700;
    background: transparent;
  }

  ul.navigation-level1 li.page-login a {
    padding-left: 42px;
  }

  ul.navigation-level1 li.page-login a:before {
    @include font-awesome($fa-var-sign-in);
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    font-weight: 900;
    font-size: 0.9rem;
  }

  ul.navigation-level1 li.page-search a {
    position: relative;
    color: transparent;
    font-size: 0;
    width: 30px;
  }

  ul.navigation-level1 li.page-search a:before {
    @include font-awesome($fa-var-search);
    position: absolute;
    visibility: visible;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 900;
    font-size: 0.9rem;
    color: #000;
  }

  /* Level 2 */

  ul.navigation-level2 {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 240px;
    margin: 0;
    padding: 0;
    background: #fff;
    list-style-type: none;
    -moz-box-shadow: 0 0 3px $lighter-grey;
    -webkit-box-shadow: 0 0 3px $lighter-grey;
    box-shadow: 0 0 3px $lighter-grey;
    z-index: 9999;
  }

  ul.navigation-level2:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
    right: 10px;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    z-index: 2;
  }

  ul.navigation-level2:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -12px;
    right: 8px;
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.08);
    z-index: 1;
  }

  ul.navigation-level2 li a {
    display: block;
    padding: 10px 15px;
    height: auto;
    line-height: 1.5rem;
    border-bottom: 1px solid #ddd;
    white-space: pre-wrap;
  }

  ul.navigation-level2 li:last-child a {
    border: none;
  }

  ul.navigation-level2 li > a:hover {
    background: $light;
  }

  ul.navigation-level2 li.is-active > a {
    color: $bright;
  }

  ul.navigation-level2 li.is-active > a:hover {
    background: transparent;
  }

  ul.navigation-level1 > li:hover:not(.page-reiseziele) > ul {
    display: block;
  }

}